<template>
  <div class="relative-position">
    <blur-background
      v-if="!$q.platform.is.mobile"
      blur="xl"
      style="opacity: 0.4"
    />
    <div
      v-if="body"
      class="relative-position"
      :class="[`text-${textSize}`, textClasses, textColor]"
      :style="`line-height: ${lineHeight}`"
    >
      {{ body }}
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useColorStore } from "src/stores/color";
import BlurBackground from "src/components/blur/BlurBackground.vue";

defineOptions({ name: "TextBody" });

const props = defineProps({
  body: { type: [Boolean, String], default: false },
  lineHeight: { type: Number, default: 1.3125 },
  textClasses: { type: String, default: "text-center text-petite" },
  textSize: { type: String, default: "subtitle2" },
});

const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
</script>
